<template>
  <div class="CreateCandidature" >
    <b-form v-on:submit.prevent="createCandidature()">
      <div class="gavc-field"
         v-bind:class="{ 'is-error': description && $v.description.$dirty ? $v.description.$error : null  }">
        <label class="gavc-label" for="create-candidature-description-input">
          Présentez-vous rapidement et décrivez votre projet pour l'entreprise
        </label>
        <textarea
          class="gavc-textarea"
          id="create-candidature-description-input"
          maxlength="10000"
          rows="10"
          @input="$v.description.$touch()"
          type="text"
          placeholder="Qui êtes-vous ?
Pourquoi l’annonce vous intéresse ?
Pourquoi vouloir reprendre une entreprise ?
Avez-vous déjà crée / repris une entreprise par le passé ?
Quelles sont vos compétences dans le secteur d’activité ?
Quelles sont vos ambitions pour cette entreprise ?
Pourquoi vous et pas un autre repreneur ?
Vos cordonnées / disponibilités ?"
          v-model="description"
        >
        </textarea>
      </div>

      <div class="row">
        <div class="col-12 text-right">
          <button
            class="gavc-btn mt-2"
            type="submit"
            :disabled="isInvalidCandidature">
              Créer votre candidature
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'

import { APIRepreneur } from '@/api/APIRepreneur'

const apiRepreneurService = new APIRepreneur()

export default {
  name: 'CreateCandidature',
  props: {
    offreCessionId: {
      type: Number
    }
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      description: ``,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidCandidature () {
      if (this.$v.description.$invalid) {
        return true
      }
      return false
    }
  },
  validations: {
    description: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(5000)
    }
  },
  methods: {

    createCandidature () {
      this.$store.commit('loadFromAPI')
      const data = {
        'offre_cession': this.offreCessionId,
        "description": this.description,
      }
      apiRepreneurService.createCandidature(this.token, data)
      .then(() => {
        this.$bvToast.toast('Toast body content', {
          title: 'Candidature créée',
          variant: 'success',
          solid: true
        })
        this.$router.push('/repreneur/tableau-de-bord')
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('CreateCandidature createCandidature API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },
  }
}
</script>

<style scoped>
#text1::-webkit-input-placeholder::after {
    display:block;
    content:"Line 2\A Line 3";
}
</style>